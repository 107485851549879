import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './style.scss'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import useWindowSize from '../../hooks/useWindowsSize'

const Carousel = ({ images }: { images: string[] }) => {
  const CustomPrevArrow: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
    <div {...props} className="custom-prev-arrow">
      <div className="arrow-background">
        <FaChevronLeft color="white" />
      </div>
    </div>
  )

  const CustomNextArrow: React.FC<React.HTMLAttributes<HTMLDivElement>> = props => (
    <div {...props} className="custom-next-arrow">
      <div className="arrow-background">
        <FaChevronRight color="white" />
      </div>
    </div>
  )
  const { width } = useWindowSize()
  const getSize = () => {
    if (width) {
      if (width > 768 && width <= 992) {
        return 3
      }
      if (width >= 576 && width < 992) {
        return 2
      }
    }
    return 3.5
  }
  const settings = {
    infinite: true,
    slidesToShow: getSize(),
    centerMode: true,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  }

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div className="element-carousel" key={index}>
          <div className="container-image">
            <img src={image} alt={`Image ${index + 1}`} />
          </div>
        </div>
      ))}
    </Slider>
  )
}

export default Carousel
