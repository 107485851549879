import React from 'react'
import './style.scss'
import Description from '../../components/Eura/Description'
import Gallery from '../../components/Eura/Gallery'
import ContactButton from '../../components/Eura/ContactButton'
import Objective from '../../components/Eura/Objetive'
import VideoGallery from '../../components/Eura/VideoGallery'
import ProjectDescription from '../../components/Eura/ProjectDescription'
import WhatsappButton from '../../components/Eura/WhatsappButton'
const Eurafrica = () => {
  return (
    <div className="container-page">
      <div className="background">
        <img src="/images/eura/background.webp" alt="Eurafrica Background" />
      </div>
      <div className="container-responsive">
        <Description />
        <Gallery />
        <Objective />
        <VideoGallery />
        <ProjectDescription />
        <WhatsappButton />
      </div>
    </div>
  )
}

export default Eurafrica
