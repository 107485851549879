// ImageViewer.tsx
import React from 'react'
import { Close, ChevronLeft, ChevronRight } from '@mui/icons-material'
import './style.scss'

interface ImageViewerProps {
  src: string
  isVideo: boolean
  onClose: () => void
  onPrevious: () => void
  onNext: () => void
}

const ImageViewer: React.FC<ImageViewerProps> = ({
  src,
  isVideo,
  onClose,
  onPrevious,
  onNext
}) => {
  return (
    <div className="image-viewer-overlay">
      <div className="image-video-container">
        {isVideo ? (
          <video src={src} controls>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={src} alt="Viewer" />
        )}
      </div>
      <button className="close-button" onClick={onClose}>
        <Close fontSize={'large'} />
      </button>
      <button className="previous-button" onClick={onPrevious}>
        <ChevronLeft fontSize={'large'} />
      </button>
      <button className="next-button" onClick={onNext}>
        <ChevronRight fontSize={'large'} />
      </button>
    </div>
  )
}

export default ImageViewer
