import React from 'react'
import './style.scss'
import Carousel from '../../Carousel'
import GalleryFlex from '../GalleryFlex'
const Gallery = () => {
  const images = Array.from(
    { length: 14 },
    (_, index) => `/images/eura/carousel/${index + 1}.webp`
  )

  return (
    <div className="container-gallery">
      <div className="container-title">
        <h2>Galería</h2>
      </div>
      <div className="container-gallery-images">
        <Carousel images={images} />
      </div>
      <GalleryFlex />
      <div className="container-text">
        <div className="container-text-bg">
          <h2>"Desde Tenerife al Origen"</h2>
        </div>
      </div>
    </div>
  )
}

export default Gallery
