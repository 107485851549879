import React from 'react'
import './style.scss'

const GalleryFlex = () => {
  return (
    <div className="galeria-flex">
      {[...Array(9)].map((_, index) => (
        <div key={index} className="imagen">
          <img
            src={`/images/eura/ponentes/ponente_${index + 1}.webp`}
            alt={`Ponente ${index + 1}`}
          />
        </div>
      ))}
    </div>
  )
}

export default GalleryFlex
