// Description.js
import React from 'react'
import './style.scss'

const Description = () => {
  return (
    <div className="description-container">
      <div className="yellow-line" />
      <div className="container">
        <div className="left-column">
          <img src="/images/eura/eurafricaribeTitle.webp" />
          <div className="instagram-container">
            <a
              href="https://www.instagram.com/eurafricaribe.tenerife/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/eura/instagram.png" alt="Instagram" />
            </a>
            <p>@eurafricaribe.tenerife</p>
          </div>
          <p>Email: eurafricaribe.prensa@gmail.com</p>
          <p>Teléfono: +632 202 126</p>
        </div>
        <div className="right-column">
          <div className="text-container">
            <p className="text-description">
              Es un proyecto original de Santa Morel concebido para desarrollar acciones
              académicas y socioculturales entre nuestros países afines. Presentado a las
              autoridades dominicanas en el año 2021 en España, que indaga en la huella de los
              antiguos pobladores de Canarias y de qué manera se encontraron con los Criollos
              dominicanos en la colonia. Una historia común desde África, Canarias y el Caribe
              que aún perdura hoy día.
            </p>
          </div>
          <div className="image-container">
            <img src="/images/eura/festival.webp" alt="Sample" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Description
