import React from 'react'
import './style.scss'
const Objective = () => {
  return (
    <div className="objetive-container">
      <div className="description-final">
        <h2>Objetivo</h2>
        <p>
          Crear nuevas y mejores oportunidades para los artistas emigrantes y para todas las
          nuevas generaciones. Facilitar canales de conexión académica hacia nuevas
          oportunidades profesionales. El Proyecto EurÁfriCaribe Tenerife es más que un
          Corredor que facilita movilidad, es una propuesta de unidad entre pueblos hermanos,
          para el desarrollo integral de la identidad heredada en múltiples áreas en las
          personas migrantes y para los/las personas nativas de los tres continentes.
        </p>
      </div>
      <div className="image-container-description">
        <img src={'/images/eura/objetivo.webp'} alt={'IFestivalIntercultural'} />
      </div>
    </div>
  )
}

export default Objective
