// VideoGallery.tsx
import React, { useState } from 'react'
import ImageViewer from '../ImageViewer' // Asumiendo que tienes el componente ImageViewer
import './style.scss'

const VideoGallery: React.FC = () => {
  const items = Array.from({ length: 12 }, (_, index) => ({
    id: index + 1,
    type: index % 2 === 0 ? 'image' : 'video' // Supongamos que cada elemento par es un video
  }))
  const [selectedItem, setSelectedItem] = useState<number | null>(null)

  const openViewer = (id: number) => {
    console.log(id)
    setSelectedItem(id)
  }

  const closeViewer = () => {
    setSelectedItem(null)
  }

  const navigate = (direction: 'previous' | 'next') => {
    if (selectedItem !== null) {
      const currentIndex = items.findIndex(item => item.id === selectedItem)
      const newIndex =
        direction === 'next'
          ? (currentIndex + 1) % items.length
          : (currentIndex - 1 + items.length) % items.length

      setSelectedItem(items[newIndex].id)
    }
  }

  return (
    <div className="gallery-flex-container">
      {items.map(item => (
        <div key={item.id} className="gallery-item" onClick={() => openViewer(item.id)}>
          {item.type === 'image' ? (
            <img src={`/images/eura/carousel/${item.id}.webp`} alt={`Image ${item.id}`} />
          ) : (
            <video controls={true}>
              <source src={`/images/eura/videos/example.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ))}

      {selectedItem !== null && (
        <ImageViewer
          src={
            items[selectedItem - 1].type === 'image'
              ? `/images/eura/carousel/${selectedItem}.webp`
              : `/images/eura/videos/example.mp4`
          }
          isVideo={items[selectedItem - 1].type === 'video'}
          onClose={closeViewer}
          onPrevious={() => navigate('previous')}
          onNext={() => navigate('next')}
        />
      )}
    </div>
  )
}

export default VideoGallery
