import React from 'react'
import './style.scss'
const ProjectDescription = () => {
  return (
    <div className="project-description">
      <div className="description-final">
        <p>
          Es un proyecto que indaga en la huella de nuestra historia común desde África, Islas
          Canarias y el Caribe persiguiendo el rastro científico del comercio colonial. Tras
          dos años de investigación acerca de la postura decolonial heredada que afectó de
          alguna manera al futuro de la comunidad actual afrodescendiente.
        </p>
        <p>
          Realizamos eventos interculturales programados desde 2023 hasta 2027. Acabamos de
          conmemorar el día de La Hispanidad una actividad que será recurrente en los
          siguientes 4 años.
        </p>
        <p>
          El 12 de octubre de 1492 fue un hecho histórico relevante y digno de resaltar en los
          aspectos histórico y sociológicos donde se abrieron dos focos geopolíticos de gran
          imprtancia para la humanidad y América hasta la actualid con grandes aportes a la
          diversidad entre Canarias y República Dominicana de mutuo interés.
        </p>
        <p>
          El origen de esta idea empezó hace unos 14 años. En España esta investigación inició
          en 2019 y posteriormente comenzó la indagación y las gestiones con el departamento de
          Relaciones Internacionales de la Universidad Autónoma de Santo Domingo, UASD en 2021.
          Se fueron incluyendo antiguos proyectos cortos, los cuales ya se habían puesto en
          práctica en Estados Unidos/Miami. Para la implementación en Canarias la coordinadora
          de este proyecto fue uniendo y abundando en investigaciones sociológicas en la
          Antropología Cultural y otras áreas, según las necesidades de la sociedad canaria y
          caribeña. Se proyectó para construir ALIANZAS ESTRATÉGICAS para aportar solidez, así
          como beneficios en materia científica, profesional y laboral para personas en riesgo
          de exclusión y/o con discapacidad.
        </p>
        <p>
          Se realizaron propuestas similares en Orlando, Barcelona, Tarragona y República
          Dominicana antes de llegar a Tenerife. Es efectivo como instrumento de integración
          sociocultural con la movilidad de los eventos y conseguir que haya participación
          masiva de los ciudadanos en los actos. Actúa con la antropología aplicada en aspectos
          necesarios para entender la afrodescendencia en Canarias y en el Caribe.
        </p>
      </div>
      <div className="image-container-description">
        <img src={'/images/eura/IFestivalIntercultural.jpg'} alt={'IFestivalIntercultural'} />
      </div>
    </div>
  )
}

export default ProjectDescription
