// Contact.tsx
import React, { useState } from 'react'
import { FaPhoneAlt } from 'react-icons/fa'
import './styles.scss'
import ChatForm from '../ChatForm'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
const WhatsappButton: React.FC = () => {
  const onClick = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=34632202126&text=Hola!%20Santa%20`,
      '_blank'
    )
  }
  return (
    <div className="whatsapp-container">
      <div className="floating-button" onClick={onClick}>
        <WhatsAppIcon fontSize="large" />
        {/* <span className="text">Contáctanos</span> */}
      </div>
    </div>
  )
}

export default WhatsappButton
