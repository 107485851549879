import * as React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import CssBaseline from '@mui/material/CssBaseline'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'

import './style.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ImportantDevices, PropaneSharp } from '@mui/icons-material'
import { useEffect } from 'react'
import { useMediaQuery } from '@mui/material'
import { itemArray, langArray } from '../../constant'

const drawerWidth = 240

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean
}>(({ theme, open }) => ({
  flexGrow: 1,
  //padding: theme.spacing(3), //Este lo soluciona todo
  //margin: '0px !important',
  // marginLeft: '0px !important',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
    // marginLeft: 0, // Éste creo que no hace nada
  })
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

export default function PersistentDrawerLeft(props: any) {
  // se los coloco yo para que los items del Drawer hagan lo que yo quiero
  const navigate = useNavigate()
  const [t, i18n] = useTranslation('global')

  // para navegar, va en una función
  // deuda técnica, ¿un hook para ésto?  useMediaQuerry
  let location = useLocation().pathname.split('/')[1]
  const goTo = (lang: string) => {
    if (isMobileScreen) {
      handleDrawerClose()
    }
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
    navigate(`${location}/${lang}`)
  }
  const navigateTo = (text: string) => {
    if (isMobileScreen) {
      handleDrawerClose()
    }
    // navigate(`${text}/${i18n.language}`)
    navigate(`${text}`)
  }
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'))
  useEffect(() => {
    if (isMobileScreen) {
      handleDrawerClose()
    }
  }, [isMobileScreen])

  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" className="appBar" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div"></Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemArray.map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => navigateTo(text)}>
                <ListItemText primary={t(`header.${text}`)} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider />
        <List>          
        {langArray.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => goTo(text)}>
              <img src={`/icons/${text}.png`} className="labelDrawer" alt="PonerCountriAqui" style={{ cursor: 'pointer' }} />
              <ListItemText primary={t(`header.${text}`)} />
            </ListItemButton>
          </ListItem>
        ))}
        </List>
        <Divider /> */}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Box>
  )
}
